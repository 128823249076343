import React from "react";
import Faq from "react-faq-component";
import "./index.css";


const data = {
    title: "",
    rows: [
        {
            title: "How do I pay my property tax?",
            content: `You can pay your property tax by searching your property either with Unique Property Id / Old Property Id or with property details like mohalla, door number and owner name and using credit / debit cards, netbanking to make an online payment`,
        },
        {
            title: "How to apply & pay Fire NOC?",
            content: `Fire NOC issued by the respective state fire service verifies that a building is resistant or unlikely to observe any fire related accidents. By meeting certain guidelines laid down by the fire department, an applicant can obtain NOC for his residential/ commercial building.`,
        },
        {
            title: "How can I apply for water connection?",
            content: `You can apply for water connection by register on mSeva portal as customer. To apply for water connection you can enter information like property id , name and mobile number with address.`,
        },
        {
            title: "What is the difference between Property Unique ID and Old Property ID?",
            content:
                "Old Property Id is an Id previously given by your Local body and can be found on your old payment receipts while Property Unique Id is an Id generated by mSeva system and can be seen post searching your property on this portal. You can search your property using both Ids",
        },
        {
            title: "Why should I apply for a Trade License?",
            content: `A Trade License is permission issued by an Urban Local Body (ULB) to conduct specific trade or business according to the relevant rules, standards and safety guidelines on premises for which it has been issued. Any unauthorised running of trade is an offence which may result in a substantial penalty and subsequent prosecution. Business owners must apply for Trade License before commencement of any trade.`,
        }, 
        {
            title: "Can I apply for a mutation of my registered property?",
            content: `Yes. You can apply for Mutation online by clicking on ‘Register Property' link under Property Tax section above. Please login, search for your property and open up the details page. You can initiate Mutation process by clicking on 'Transfer Ownership’ option`,
        },    
        {
            title: "My property details have changed. How do I make these changes online? Do I need to submit documents while making changes to my existing property details?",
            content: `Currently, you will have to visit your ULB office to make these changes along with documents like ID Proof, Proof of Property Ownership and any other relevant document`,
        },  
        {
            title: "How do I check my property tax online?",
            content: `You can check your property tax online by searching for your property`,
        },
        {
            title: "How do I access previous payments made for my property?",
            content: `You can view previous payments made for your property by visiting <a href="https://mseva.lgpunjab.gov.in/citizen/language-selection" target="_blank">this</a> page. Please login, search for your property and open up the details page. You can view previous payments under 'Payment History’ section`,
        },
        {
            title: "How do I renew my trade license?",
            content: `You can renew your Trade License by visiting ‘Renewal of License' option under Trade License section above. Please login, go to ‘My Applications’ under Trade License and click on 'Renew’ for License to be renewed`,
        },
        {
            title: "I have paid my trade license fee. How do I download the payment receipt?",
            content: `You can download payment receipt for your Trade License by visiting <a href="https://mseva.lgpunjab.gov.in/citizen/language-selection" target="_blank">this</a> page. Please login, go to ‘My Applications’ under Trade License and click on License number. You can click on ‘Download’ button to download your receipt`,
        },
        {
            title: "My license application is approved. How do I download the license certificate?",
            content: `You can download license certificate for your Trade License by visiting <a href="https://mseva.lgpunjab.gov.in/citizen/language-selection" target="_blank">this</a> page. Please login, go to ‘My Applications’ under Trade License and click on License number. You can click on ‘Download’ button to download your certificate`,
        },
    ],
};

const styles = {
    // bgColor: 'white',
    titleTextColor: "black",
    rowTitleColor: "black",
    rowTitleTextSize: "16px",
    // rowContentColor: 'grey',
    // arrowColor: "red",   
    rowContentPaddingTop:"12px",

};

const config = {
     animate: true,
     arrowIcon: "+",
    // tabFocus: true
};


export default function SectionFAQs() {

    return (
        <div id="faqs">
            <Faq
                data={data}
                styles={styles}
                config={config}
            />
        </div>
    );
}